import { IoWarning } from "react-icons/io5";
import useRequests from "../../../hooks/requests/useRequests";
import GenericModal from "../../../utils/GenericModal";

export default function DeleteRequestsModal(props) {
    const { name } = props;
    const [__, { deleteRequests, toggleMe }] = useRequests(props);

    return (
        <GenericModal
            className="w-1/2 xl:w-1/3 2xl:w-1/4 text-base text-black rounded-lg"
            showCloseButton={true}
            exitClick={true}
            toggleMe={toggleMe}
        >
            <div className='font-semibold w-full text-left px-4 py-3 border-b text-sm flex items-end gap-1'>
                <IoWarning className='text-red-400 w-5 h-5' />Suppression de requêtes
            </div>
            <div className='p-8 text-justify'><b>ATTENTION ! </b>Vous êtes sur le point de supprimer <b>TOUTES</b> les requêtes de cette entreprise : <b>{name}</b>
            <p><b>Celà imlique la réinitialisation du compteur de requêtes de l'entreprise. Êtes vous sûr de vouloir continuer ?</b></p></div>


            <div className='flex w-full justify-end gap-4 py-2 border-t px-4'>
                <button onClick={toggleMe} className='rounded border bg-white text-black text-sm font-light py-1 px-2'>
                    Annuler
                </button>
                <button onClick={deleteRequests} className='rounded bg-gray-600 hover:bg-gray-700 text-white text-sm py-1 px-2 transform duration-300'>
                    Supprimer
                </button>
            </div>
        </GenericModal>
    );
}

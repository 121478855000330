import React, { useState } from "react";
import { ACTION, GENERIC_MODAL_ACTIONS } from "../../../context/actionTypes";
import useManageFeedBacks from "../../../hooks/feedbacks/useManageFeedBacks";
import ListDisplayer from "../../../utils/ListDisplayer";
import { MdOutlineReply } from "react-icons/md";
import {getFaceImage, getFaceIndex} from "./ReplyFeedbackModal";
import TimeRangeSelector from "../dashboard/TimeStamp";
import ButtonDropdown from "../../../utils/ButtonDropdown";
import {GoChevronRight} from "react-icons/go";
import { IoRemoveCircleOutline } from "react-icons/io5";
import './feedbacks.css'

// Obtain the dot element for a given status
const getStatusPointOnly = (status? : boolean) => {
    return <div className={`rounded-full h-2 w-2 ${status === null ? 'bg-gray-700' : (status ? 'bg-response-medium' : 'bg-response-positive')}`}/>
}

// Obtain the dot and the associated text for a give status
const getStatusPoint = (status? : boolean) => {
    return (
        <div className='flex items-center gap-2'>
            {getStatusPointOnly(status)}
            {status ? 'En attente' : 'Traité'}
        </div>
    );
}

export default function ManageFeedbacks() {
    const [{ feedbacks }, { loadFeedBacks,dispatch }] = useManageFeedBacks();
    const [faceSelectedFilters, setFaceSelectedFilters] = useState([]);
    const [statusSelectedFilter, setStatusSelectedFilter] = useState(null);
    const [startDateSelectedFilter, setStartDateSelectedFilter] = useState(null);
    const [endDateSelectedFilter, setEndDateSelectedFilter] = useState(null);

    const handleFaceFilterClick = (selectedFace? : string) => {
        setFaceSelectedFilters((prevSelected) =>
            prevSelected.includes(selectedFace)
                ? prevSelected.filter((face) => face !== selectedFace)
                : [...prevSelected, selectedFace]
        );
    };

    const handleStatusFilterClick = (selectedStatus: boolean | null) => {
        setStatusSelectedFilter((prevSelected: boolean | null) => selectedStatus === prevSelected ? null : selectedStatus);
    }

    const handleDateChange = (start: string, end: string) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        setStartDateSelectedFilter(startDate);
        setEndDateSelectedFilter(endDate);
    }

    // Face filter
    let filteredFeedbacks = faceSelectedFilters.length > 0
        ? feedbacks.filter(feedback => faceSelectedFilters.includes(feedback?.data?.face))
        : feedbacks;

    // Status filter
    filteredFeedbacks = statusSelectedFilter !== null
        ? filteredFeedbacks.filter(feedback => (feedback?.handled_at === null) === statusSelectedFilter)
        : filteredFeedbacks;

    // Date filter
    filteredFeedbacks = startDateSelectedFilter !== null && endDateSelectedFilter !== null
    ? filteredFeedbacks.filter(feedback => {
        const parsedCreatedAtDate = new Date(feedback.created_at);
        return parsedCreatedAtDate > startDateSelectedFilter && parsedCreatedAtDate < endDateSelectedFilter;
    }) : filteredFeedbacks;

    const columnsOnSummary = [
        {
            label: 'Reaction',
            key: 'faceIndex',
            colSpan: 1,
            template: (data: { faceIndex: number; }) => getFaceImage(data.faceIndex),
        },
        {
            label: 'Avis',
            key: 'content',
            colSpan: 4,
        },
        {
            label: 'Statut',
            key: 'status',
            colSpan: 1,
            className: "truncate",
            template: (data: { status: boolean; }) => (
                <div data-tooltip-id="users-tooltip" data-tooltip-content={data.status ? 'En attente' : 'Traité'}>
                    {getStatusPoint(data.status)}
                </div>
            ),
        },
        // {
        //     label: 'Statut',
        //     key: 'embedding_collection',
        //     colSpan: 2,
        //     template: (data) => <div className='pl-4 text-sm'>{getStatus(data.status)}</div>,
        // },
        {
            label: 'Créé le',
            key: 'created_at',
            type: 'date',
            colSpan: 1,
        },
    ];

    const actionsMenu = [
        {
            action: GENERIC_MODAL_ACTIONS.REPLY_FEEDBACK,
            label: 'Répondre',
            enabled: (e) => true,
            Icon: MdOutlineReply,
        },
    ];

    return (
        <div className="px-8">
            <div className="flex flex-col gap-8 pb-8">
                <div className='font-bold text-2xl text-black'>Retours</div>
                {/* Boutons de filtrage */}

                <div className="flex flex-row gap-4 items-center self-end title-component">
                    <h2 className="text-black mr-8 flex items-center gap-1">
                        <div>Outils de filtrage</div>
                        <GoChevronRight className="h-8"/>
                    </h2>
                    <div className="flex gap-4">
                        <div className="relative flex items-center justify-center">
                            <button
                                onClick={() => handleFaceFilterClick('good')}
                                className={`absolute inset-0 w-full h-full rounded-full border-2 ${faceSelectedFilters.includes('good') ? 'border-[#81DA61] shadow-md' : 'border-white'}`}
                                style={{boxSizing: 'border-box'}}
                            >
                            </button>
                            <img className='w-8 h-8' src='/green.svg' alt="very satisfied"/>
                        </div>
                        <div className="relative flex items-center justify-center">
                            <button
                                onClick={() => handleFaceFilterClick('medium')}
                                className={`absolute inset-0 w-full h-full rounded-full border-2 ${faceSelectedFilters.includes('medium') ? 'border-[#FFA552] shadow-md' : 'border-white'}`}
                                style={{boxSizing: 'border-box'}}
                            >
                            </button>
                            <img className='w-8 h-8' src='/orange.svg' alt="satisfied"/>
                        </div>
                        <div className="relative flex items-center justify-center">
                            <button
                                onClick={() => handleFaceFilterClick('bad')}
                                className={`absolute inset-0 w-full h-full rounded-full border-2 ${faceSelectedFilters.includes('bad') ? 'border-[#FC6969] shadow-md' : 'border-white'}`}
                                style={{boxSizing: 'border-box'}}
                            >
                            </button>
                            <img className='w-8 h-8' src='/red.svg' alt="unsatisfied"/>
                        </div>
                        <div className="relative flex items-center justify-center">
                            <button
                                onClick={() => handleFaceFilterClick(null)}
                                className={`absolute inset-0 w-full h-full rounded-full border-2 ${faceSelectedFilters.includes(null) ? 'border-[#919192] shadow-md' : 'border-white'}`}
                                style={{boxSizing: 'border-box'}}
                            >
                            </button>
                            <img className='w-8 h-8' src='/grey.svg' alt="neutral"/>
                        </div>
                    </div>
                    <ButtonDropdown defaultValue="all" options={[
                        {label: "Tous", value: null, key: "all", icon: getStatusPointOnly(null)},
                        {label: "En attente", value: true, key: "waiting", icon: getStatusPointOnly(true)},
                        {label: "Traité", value: false, key: "done", icon: getStatusPointOnly(false)}
                    ]} onChange={handleStatusFilterClick}/>
                    <TimeRangeSelector onDateChange={handleDateChange} defaultValue="all"/>
                    
                </div>
            </div>

            <ListDisplayer
                columnsOnSummary={columnsOnSummary}
                items={filteredFeedbacks.map(feedback => ({
                    ...feedback,
                    faceIndex: getFaceIndex(feedback.data?.face),
                    status: feedback.handled_at === null
                }))}
                actionsMenu={actionsMenu}
                reload={loadFeedBacks}
                defaultSortKey="created_at"
            />
            <div className="feedbackDelete">
            <button
                        className='btn-transition bg-perso-blue hover:bg-opacity-75 mb-4 feedbackDelete'
                        onClick={() => {      
                            dispatch({
                                type: ACTION.SET_GENERIC_MODAL,

                                payload: {                                
                                    props:{reload:loadFeedBacks},
                                    action: GENERIC_MODAL_ACTIONS.DELETE_FEEDBACKS
                                }
                            })
                        }}
                    >
                        <IoRemoveCircleOutline className='h-5 w-5' />
                        <div>Supprimer les retours</div>
                    </button>
                    </div>
        </div>
    );
}

import React from 'react'
import { useAppContext } from '../../context/Context';
import api from '../../utils/api';
import { setBottomBarError, setBottomBarSucces } from '../../utils/Functions';
import { ACTION } from '../../context/actionTypes';

export default function useRequests(props) {
    const [_, dispatch] = useAppContext();
    const { id, reload } = props;

    const deleteRequests = async () => {
        try {
            await api.delete(`/request/company/${id}`);
            await reload();
            toggleMe();
            setBottomBarSucces(dispatch, "Les requêtes ont été supprimés")

        } catch (err) {
            console.error("Failed to delete requests", err);
            setBottomBarError(dispatch, err);
        }
    };
    const deleteSensitiveDataRequests = async () => {
        try {
            await api.patch(`/request/company/${id}`);
            await reload();
            toggleMe();
            setBottomBarSucces(dispatch, "Les données sensibles ont été supprimés")

        } catch (err) {
            console.error("Failed to delete sensitive data from requests", err);
            setBottomBarError(dispatch, err);
        }
    };

    const toggleMe = () => dispatch({ type: ACTION.SET_GENERIC_MODAL, payload: null });

    return [{}, { deleteRequests, toggleMe,deleteSensitiveDataRequests }];
}

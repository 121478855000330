import {ACTION, BOTTOM_BAR_SEVERITY} from "../context/actionTypes"

export function checkEmail(email) {
    console.log(email)
    let countAt = 0
    let AllowedChar = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890éàèù@.!#$%&'*+-/=?^_`{|"
    let specialChar = "@.!#$%&'*+-/=?^_`{|"
    for (let i = 0; i < email?.length; i++) {
        //console.log(i)
        if (!AllowedChar.includes(email[i])) {
            return false
        }
        if (email[i] === "@") {
            countAt++
        }
    }
    if (countAt !== 1) {
        return false
    }
    let endEmail = email.split("@").slice(-1)[0]
    //console.log(endEmail.substring(endEmail.length - 2))
    if (!endEmail.includes(".") || endEmail.substring(endEmail.length - 2).includes(".")) {
        return false
    }
    return !(email.includes("..") || specialChar.includes(email[0]) || specialChar.includes(email[email.length - 1]));
}

export function sortDataAlphabetically(data, sortKey) {
    if (data.length <= 0) return data;
    if (sortKey.key === "int_size") {
        return [...data].sort((a, b) => {

            // Get the values, ensuring they are strings, and handle undefined or null
            const valueA = a[sortKey.key] !== undefined && a[sortKey.key] !== null ? a[sortKey.key] : '';
            const valueB = b[sortKey.key] !== undefined && b[sortKey.key] !== null ? b[sortKey.key] : '';

            // Determine the sorting order based on the direction
            return sortKey.direction
                ? valueA - valueB // For ascending order
                : valueB - valueA; // For descending order
        });
    }
    return [...data].sort((a, b) => {
        // Get the values, ensuring they are strings, and handle undefined or null
        const valueA = a[sortKey.key] !== undefined && a[sortKey.key] !== null ? a[sortKey.key].toString() : '';
        const valueB = b[sortKey.key] !== undefined && b[sortKey.key] !== null ? b[sortKey.key].toString() : '';

        // Determine the sorting order based on the direction
        return sortKey.direction
            ? valueA.localeCompare(valueB) // For ascending order
            : valueB.localeCompare(valueA); // For descending order
    });
}


export const formatDate = (dateStr) => {
    return new Date(dateStr).toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
};

export function humanFileSize(bytes, si = true, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}

/** dispatch the action set bottom bar as a success
 *
 * @param dispatch
 * @param message
 */
export const setBottomBarSucces = (dispatch, message) => {
    dispatch({
        type: ACTION.SETBOTTOMBAR,
        payload: {
            open: true,
            severity: BOTTOM_BAR_SEVERITY.SUCCESS,
            message: message,
        },
    })
}
/** dispatch the action setbottombar as an error
 *
 * @param dispatch
 * @param error
 */
export const setBottomBarError = (dispatch, error) => {
    const message = error.response?.data?.detail || error?.message || error.response?.data || JSON.stringify(error)
    dispatch({
        type: ACTION.SETBOTTOMBAR,
        payload: {
            open: true,
            severity: BOTTOM_BAR_SEVERITY.ERROR,
            message: JSON.stringify(message)
        },
    })
}
/** dispatch the action setbottombar as a warning-info
 *
 * @param dispatch
 * @param message
 */
export const setBottomBarWarning = (dispatch, message) => {
    dispatch({
        type: ACTION.SETBOTTOMBAR,
        payload: {
            open: true,
            severity: BOTTOM_BAR_SEVERITY.INFO,
            message,
        },
    })
}

export function getFrenchDayInitialWithDay(dateStr) {
    const date = new Date(dateStr);
    const today = new Date();

    const getDayFromDateString = (dateString: string): string => {
        return dateString.split('-')[2];
    };


    const getDay = getDayFromDateString(dateStr);
    let getMonth;

    // Vérifiez si la date est dans le mois précédent
    if (date.getMonth() < today.getMonth() || (date.getMonth() === 11 && today.getMonth() === 0)) {
        getMonth = ('0' + (date.getMonth() + 1)).slice(-2);
    } else {
        getMonth = ('0' + (today.getMonth() + 1)).slice(-2);
    }

    return `${getDay}/${getMonth}`;

}
